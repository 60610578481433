import { datadogRum } from '@datadog/browser-rum';
import { BlockEnvironmentProvider } from '@zillow/cms-blocks';
import { appendZhlParams } from '@zillow/mortgage-urls';
import { AppWrapper } from '@zillow/page-frame-next-components';
import { page, setdim } from '@zillow/universal-analytics';
import {
    PagePropsProvider,
    ThemeCmsConstellation,
    ThemeSkunkWorksCmsConstellation,
    getCmsInfoForPage,
} from '@zillow/zrs-cms';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import 'regenerator-runtime/runtime';
import { ThemeProvider } from 'styled-components';

import { clientProfilerConfig } from '../../server/config';
import VisualRefreshFonts from '../components/VisualRefreshFonts';
import { trackExposureEvent } from '../util/optimizely';

function isMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return (
        typeof window !== 'undefined' &&
        toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem))
    );
}

if (typeof window !== 'undefined') {
    datadogRum.init({
        applicationId: '670a99d8-3d41-4858-ae1f-cb66797af887',
        clientToken: 'pubb1845c6bcbfd371c2a207a83befcffc0',
        site: 'datadoghq.com',
        service: 'cms-constellation',
        env: process.env.NEXT_PUBLIC_ENV,
        version: process.env.NEXT_PUBLIC_SERVICE_VERSION,
        sessionSampleRate: parseInt(
            process.env.NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE || '0'
        ),
        startSessionReplayRecordingManually: true,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
    });
}

const MyApp = ({ Component, pageProps }) => {
    useEffect(() => {
        // For how-much-is-my-home-worth analytics
        const params = new URLSearchParams(window.location.search);
        if (params.has('zpid')) {
            setdim({ dimension2: params.get('zpid') });
        }
        // https://gitlab.zgtools.net/zillow/npm/universal-analytics/-/blob/master/README-NEWLANE.md#pagepath-properties-options
        // https://dataportal.zillowgroup.net/event-registry/2284
        page(
            window.location.pathname,
            {},
            {
                newLaneEvent: {
                    envelope: {
                        event_type_id: '2284',
                        event_type_version_id: '1',
                        event_template_id: '126',
                        event_template_version_id: '1',
                    },
                    clickstream_trigger: {
                        trigger_type_nm: 'view',
                        trigger_location_nm: 'cms',
                        trigger_source_nm: 'cms',
                        trigger_object_nm: 'no_trigger_object',
                    },
                    semantic: {
                        semantic_event_nm: 'view_content',
                    },
                    cms_info: getCmsInfoForPage(pageProps),
                },
            }
        );

        window.addEventListener('load', () => {
            appendZhlParams(document);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Disable Chrome's behavior where if the current user has already visited
    // the page and has scrolled to a given point, Chrome will restore that
    // scroll position and ignore the deep link.
    useEffect(() => {
        if ('scrollRestoration' in window.history && window.location.hash) {
            window.history.scrollRestoration = 'manual';
        }
    }, []);

    // Track Optimizely exposure events with Clickstream.
    useEffect(() => {
        trackExposureEvent();
    }, []);

    return (
        <AppWrapper
            clientProfilerConfig={merge(clientProfilerConfig, {
                staticDimensions: {
                    platform: isMobile() ? 'mobile' : 'desktop',
                },
            })}
        >
            <ThemeProvider
                theme={
                    pageProps.useVisualRefreshTheme
                        ? ThemeSkunkWorksCmsConstellation
                        : ThemeCmsConstellation
                }
            >
                <PagePropsProvider pageProps={pageProps}>
                    <BlockEnvironmentProvider
                        environment={process.env.NEXT_PUBLIC_ENV}
                    >
                        {pageProps.useVisualRefreshTheme && (
                            <VisualRefreshFonts />
                        )}
                        <Component {...pageProps} />
                    </BlockEnvironmentProvider>
                </PagePropsProvider>
            </ThemeProvider>
        </AppWrapper>
    );
};

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
};

export default MyApp;
